.service {
    transition: 0.25s;
    box-shadow: 0 0 0.5rem var(--light-color);
    border-radius: 0 1rem 0 1rem;
    padding: 1rem;
    height: 100%;
    max-height: 20rem;
    overflow: hidden;
    &:hover {
        background: var(--primary-color);
        ul {
            color: var(--dark-color) !important;
        }
    }
}
