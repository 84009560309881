.box {
    position: relative;
    .tooltip {
        display: none;
        position: absolute;
        top: 0;
        right: 100%;
        background: var(--secondary-color);
        padding: 0.4rem;
        color: var(--primary-color);
    }
    &:hover .tooltip {
        display: block;
    }
}
