import { Byte, Encoder } from '@nuintun/qrcode';
import { saveAs } from 'file-saver';
import { toBlob } from 'html-to-image';
import { formatDate } from 'web-utility';

export const makeDate = (year: number, mouth: number, day: number) =>
    formatDate(new Date(year, mouth, day), 'YYYY-MM-DD');

export async function saveImage(box: HTMLElement, name: string) {
    const { width, height } = self.getComputedStyle(box);

    const blob = await toBlob(box, {
        width: parseFloat(width),
        height: parseFloat(height)
    });
    saveAs(blob, name + '.png');
}

export const encodeQRC = (raw: string) =>
    `data:${new Encoder().encode(new Byte(raw)).toDataURL()}`;
