.box {
    margin: auto;
    background: white;
    width: 70vw;
    height: 45vh;
    .avatar {
        margin: 0.5rem auto;
        border-radius: 50%;
        width: 6rem;
        height: 6rem;
        object-fit: cover;
        object-position: top;
    }
}
