.title {
    position: relative;
    margin-top: 2rem;
    font-size: xxx-large;
    text-transform: uppercase;
    &::before {
        position: absolute;
        top: -2rem;
        left: 0;
        border-top: 0.4rem solid var(--primary-color);
        width: 5rem;
        content: '';
    }
    &.center {
        text-align: center;
        &::before {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
